import React, { useEffect, useReducer, useState } from "react";
import { Button, Paper, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core"
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Link } from "react-router-dom/cjs/react-router-dom";
import MainContainer from "../../components/MainContainer";
import CadastroTypebot from "../../components/CadastroTypebot";
import api from "../../services/api"
import DelTypebot from "../../components/DelTypebot";


const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const Typebot = () => {

    const [delOpen, setDelOpen] = React.useState(false);
    const [cadastroOpen, setCadastroOpen] = React.useState(false);
    const [refresh, setRefresh] = useState(false);
    const [typebots, setTypebots] = useState([])
    const [filas, setFilas] = useState([])
    const [checked, setChecked] = useState([])
    const [webHookList, setWlist] = useState([]);

    var checkedList = {}
    var webHookQueueList = {}
    
    const cadastroHandleOpen = () => setCadastroOpen(true);
    const cadastroHandleClose = () => {
        setCadastroOpen(false);
        setRefresh(!refresh)
    }

    const DelHandleOpen = () => setDelOpen(true);
    const DelHandleClose = () => {
        setDelOpen(false);
        setRefresh(!refresh)
    }

    const handleSwitch = async (e, id) => {
        checked[id] = e.checked
        setChecked(checked)
        setRefresh(!refresh)
        let resposta = {
            id: id,
            checked: e.checked
        }
        await api.post("/DflowStatus", resposta)
        
    }

    const handleChange = async (e, id) => {
        
        let idWebhook = ''
        webHookList[id] = e.target.value
        typebots.forEach(element => {
            if (element.name == e.target.value) {
                idWebhook = element.id
                webHookQueueList[element.id] = element['integracao.name']
            }
        });

        setWlist(webHookQueueList)
        let resposta = {
            id: id,
            webhook: idWebhook,
        }

        let r = await api.post("/setWebHook", resposta)

        setRefresh(!refresh)
    }

    useEffect(() => {
        const getData = async () => {
            const data = await api.get("/Typebot")

            data.data[1].map(element => {
                console.log(element[`integracao.type`])
             //   if(element.chatbotActive == 1 && element[`integracao.type`] != "Typebot"){
             //       checkedList[element.id] = 0
             //   } else {
                    checkedList[element.id] = element.chatbotActive
            //    }

                webHookQueueList[element.id] = element['integracao.name']
            })

         //   console.log(checkedList)
            console.log(data.data[1])

            setChecked(checkedList)
            setWlist(webHookQueueList)
            setTypebots(data.data[0])
            setFilas(data.data[1])
        }
        getData()
    }, [refresh])

    const classes = useStyles()
    return (
        <MainContainer>
            <CadastroTypebot
                open={cadastroOpen}
                onClose={cadastroHandleClose}
            />
            <DelTypebot
            open={delOpen}
            onClose={DelHandleClose}
            webHookList={typebots}
            />
            <MainHeader>
                <Title>Typebot</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={cadastroHandleOpen}
                    >Adicionar Typebot</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={DelHandleOpen}
                    >Deletar Typebot</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={'/integracoes'}
                    >Voltar</Button>

                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Nome
                            </TableCell>
                            <TableCell align="center">
                                Cor
                            </TableCell>
                            <TableCell align="center">
                                Status
                            </TableCell>
                            <TableCell align="center">
                                Typebot
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <>
                            {filas.map(data => (         
                                data['integracao.type'] == 'Typebot'
                                ? <TableRow key={data.id}>
                                <TableCell align="center">{data.name}</TableCell>
                                <TableCell align="center">
                                    <div className={classes.customTableCell}>
                                        <span
                                            style={{
                                                backgroundColor: data.color,
                                                width: 60,
                                                height: 20,
                                                alignSelf: "center",
                                            }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    <Switch  onChange={e => handleSwitch(e.target, data.id)} defaultChecked={checked[data.id]} ></Switch>
                                </TableCell>
                                <TableCell align="center"><Select
                                    autoWidth
                                    //value={webHook}
                                    onChange={e => handleChange(e, data.id)}
                                    defaultValue={webHookList[data.id]}
                                    value={webHookList[data.id]}
                                    id={data.id}
                                    native
                                    displayEmpty
                                > <option id={null} value={null}></option>
                                <>{typebots.map(element => (
                                    <option id={element.id} value={element.name}  >{element.name}</option>
                                ))}</></Select></TableCell>
                            </TableRow>
                            : data['integracao.type'] == null

                             ?

                              <TableRow key={data.id}>
                             <TableCell align="center">{data.name}</TableCell>
                             <TableCell align="center">
                                 <div className={classes.customTableCell}>
                                     <span
                                         style={{
                                             backgroundColor: data.color,
                                             width: 60,
                                             height: 20,
                                             alignSelf: "center",
                                         }}
                                     />
                                 </div>
                             </TableCell>
                             <TableCell align="center">
                                 <Switch  onChange={e => handleSwitch(e.target, data.id)} defaultChecked={checked[data.id]} ></Switch>
                             </TableCell>
                             <TableCell align="center"><Select
                                 autoWidth
                                 //value={webHook}
                                 onChange={e => handleChange(e, data.id)}
                                 defaultValue={webHookList[data.id]}
                                 value={webHookList[data.id]}
                                 id={data.id}
                                 native
                                 displayEmpty
                             > <option id={null} value={null}></option>
                             <>{typebots.map(element => (
                                 <option id={element.id} value={element.name}  >{element.name}</option>
                             ))}</></Select></TableCell>
                         </TableRow>

                             : 

                             <TableRow key={data.id}>
                             <TableCell align="center">{data.name}</TableCell>
                             <TableCell align="center">
                                 <div className={classes.customTableCell}>
                                     <span
                                         style={{
                                             backgroundColor: data.color,
                                             width: 60,
                                             height: 20,
                                             alignSelf: "center",
                                         }}
                                     />
                                 </div>
                             </TableCell>
                             <TableCell align="center">
                                 <Switch disabled onChange={e => handleSwitch(e.target, data.id)} defaultChecked={checked[data.id]} ></Switch>
                             </TableCell>
                             <TableCell align="center"><Select
                                 autoWidth
                                 //value={webHook}
                                 disabled
                                 onChange={e => handleChange(e, data.id)}
                                 defaultValue={webHookList[data.id]}
                                 value={webHookList[data.id]}
                                 id={data.id}
                                 native
                                 displayEmpty
                             > <option id={null} value={null}></option>
                             <>{typebots.map(element => (
                                 <option id={element.id} value={element.name}  >{element.name}</option>
                             ))}</></Select></TableCell>
                         </TableRow>
                            ))}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}


export default Typebot